// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import numbro from "numbro";
import Config from "app/config";
import { TFunction } from "react-i18next";
import currencyFormatter from "currency-formatter";

import Colors from "app/constants/Colors";
import { IconDoc, IconExcel, IconPDF } from "assets/images";
import { TUserInfo } from "utils/authClient";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import LanguageStatus from "constants/LanguageStatus";
import i18n from "i18next";
import { LayoutAnimation, Platform } from "react-native";
import { MobileExpExpenseRequestTravelUsageQuery } from "types";
import {
  FlightBookingPolicy,
  HotelPolicy,
  TransportationPolicy,
} from "screens/ExpenseRequest/ExpenseRequestDetailScreen/components/DetailTab/PolicyNote";
import { BOOKING_TYPE } from "constants/constants";
import { ReactNode } from "react";
import SharedValue = module;
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const updateLocale = require("dayjs/plugin/updateLocale");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const minMax = require("dayjs/plugin/minMax");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const duration = require("dayjs/plugin/duration");
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.updateLocale("vi", {
  weekdaysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  weekdaysShort: ["Cn", "T2", "T3", "T4", "T5", "T6", "T7"],
});

function isDebugger(user) {
  if (Config.DEBUGGER.includes(user.email) || __DEV__) {
    return true;
  }
  return false;
}

const formatMoneyWithSymbol = (value) => {
  return numbro(value || 0).formatCurrency({
    average: true,
    mantissa: 2,
    optionalMantissa: true,
    spaceSeparated: true,
  });
};

function getContentTypeFromFilename(filename) {
  const ext = filename.split(".").pop().toLowerCase();
  switch (ext) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "heic":
      return "image/heic";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "xlst":
      return "application/vnd.ms-excel.sheet.macroEnabled.12";
    case "pdf":
      return "application/pdf";
    case "xml":
      return "application/xml";
    case "zip":
      return "application/zip";
    case "eml":
      return "message/rfc822";
    case "csv":
      return "text/csv";
    case "msg":
      return "application/vnd.ms-outlook";
    default:
      return null;
  }
}
function isValidFileUpload(filename) {
  const contentType = getContentTypeFromFilename(filename);
  return Boolean(contentType);
}

function generateDocs() {
  return `RQ${Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000}`;
}

function countNumberOfDays(
  time1: string | number | dayjs.Dayjs | Date | null | undefined,
  time2: string | number | dayjs.Dayjs | Date | null | undefined
) {
  const from_date = dayjs(time1).startOf("day");
  const to_date = dayjs(time2).endOf("day");

  if (dayjs(time1).isSame(time2)) {
    return 1;
  }
  if (time1 && time2) {
    return Math.round(to_date.diff(from_date, "days", true));
  }

  return 0;
}

// function parseLocaleNumber(stringNumber) {
//   return Number(
//     stringNumber
//       .replace(new RegExp(`\\${thousandsSeparator}`, "g"), "")
//       .replace(new RegExp(`\\${decimalSeparator}`), ".")
//   );
// }

function toFixedLocale(value, numDigits) {
  const standardFixedString = value.toFixed(numDigits);
  return numStringToLocale(standardFixedString);
}

function numberToLocaleString(value) {
  const str = value.toString();
  return numStringToLocale(str);
}

function numStringToLocale(numStr) {
  if (decimalSeparator === ",") {
    return numStr.replace(".", ",");
  }
  return numStr; // Locale matches JavaScript default
}

const formatPhone = (phone) => String(phone).replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
const formatPhoneWithCountryCode = (phone) => String(phone).replace("0", "+84");
const alphanumCase = (a, b) => {
  function chunkify(t) {
    const tz = [];
    let x = 0;
    let y = -1;
    let n = 0;
    let i;
    let j;

    while ((i = (j = t.charAt(x++)).charCodeAt(0))) {
      const m = i == 46 || (i >= 48 && i <= 57);
      if (m !== n) {
        tz[++y] = "";
        n = m;
      }
      tz[y] += j;
    }
    return tz;
  }

  const aa = chunkify(a.toLowerCase());
  const bb = chunkify(b.toLowerCase());

  for (x = 0; aa[x] && bb[x]; x++) {
    if (aa[x] !== bb[x]) {
      const c = Number(aa[x]);
      const d = Number(bb[x]);
      if (c == aa[x] && d == bb[x]) {
        return c - d;
      }
      return aa[x] > bb[x] ? 1 : -1;
    }
  }
  return aa.length - bb.length;
};

const getError = (error) => {
  if (error) {
    return { color: Colors.errorColor };
  }
  return { color: Colors.fieldLabel };
};

const moneyFormat = (number, currency = "") => {
  if (!number) {
    return 0;
  }
  return String(number).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + currency;
};

function strToRBG(str) {
  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + 1 + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "#00000".substring(1, 6 - c.length) + c;
  }

  return intToRGB(hashCode(str));
}

async function uploadFile({ url, file }) {
  return axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
}

const formatMoney = (value: string | undefined | number) => {
  if (!value) {
    return "0";
  }
  /*-- in the case amount have decimals --*/
  if (value % 1 !== 0) {
    const integerPart = currencyFormatter
      .format(Math.floor(value)?.toString()?.split(" ")[0]?.replace(/\./g), {
        code: "VND",
        symbol: "",
      })
      .trim();

    const fullDecimals = value - Math.floor(value);
    const rounded = Number(fullDecimals.toFixed(3));
    const decimals = rounded === 1 ? Math.floor(fullDecimals * 1000) : rounded * 1000;

    return [integerPart, decimals].filter((x) => x).join(",");
  }
  /*-- end ---*/

  return (
    currencyFormatter
      .format(value?.toString()?.split(" ")[0]?.replace(/\./g), {
        code: "VND",
        symbol: "",
      })
      .trim() ?? "0"
  );
};

const formatDuration = (minutes: number) => {
  if (!minutes) {
    return "";
  }

  const duration = dayjs.duration(minutes, "minutes");
  const days = duration.days();
  const hours = duration.hours();
  const remainingMinutes = duration.minutes();

  const result = [];
  if (days > 0) {
    return result.push(`${days}d`);
  }
  if (hours > 0) {
    result.push(`${hours}h`);
  }
  if (remainingMinutes > 0) {
    result.push(`${remainingMinutes}m`);
  }
  return result.join(" ");
};

const getMinDate = (days: Dayjs[]) => dayjs.min(days);

const formatStringMoneyToNumber = (value: string) => {
  if (!value) {
    return 0;
  }

  return Number(value?.replace?.(/\./g, "") ?? 0);
};

const groupExpenseByRequest = (expenses: Expenses[], t?: TFunction<string, undefined>) => {
  const expenseGroupBy: Record<
    string,
    {
      id: string;
      title: string;
      doc_sequence: string;
      expenses: Expenses[];
      type: "request" | "travel" | "other";
    }
  > = {};

  expenses.forEach((expense) => {
    if (expense?.expense_travel_id) {
      if (expenseGroupBy[expense?.expense_travel_id]) {
        expenseGroupBy[expense?.expense_travel_id].expenses.push(expense);
      } else {
        expenseGroupBy[expense?.expense_travel_id] = {
          type: "travel",
          id: expense.expense_travel_id,
          title: expense.expense_travel?.expense_request?.title ?? "",
          doc_sequence: expense.expense_travel?.expense_request?.doc_sequence ?? "",
          expenses: [expense],
        };
      }

      return;
    }

    if (expense?.expense_request_id && !expense?.expense_travel_id) {
      if (expenseGroupBy[expense?.expense_request_id]) {
        expenseGroupBy[expense?.expense_request_id].expenses.push(expense);
      } else {
        expenseGroupBy[expense?.expense_request_id] = {
          type: "request",
          id: expense.expense_request_id,
          title: expense.expense_request?.title ?? "",
          doc_sequence: expense.expense_request?.doc_sequence ?? "",
          expenses: [expense],
        };
      }

      return;
    }

    if (!expense?.expense_request_id && !expense?.expense_travel_id) {
      if (expenseGroupBy.other) {
        expenseGroupBy.other.expenses.push(expense);
      } else {
        expenseGroupBy.other = {
          type: "other",
          id: expense.expense_id,
          title: t ? t("expenses_without_request") : "",
          doc_sequence: "",
          expenses: [expense],
        };
      }
    }
  });

  const listExpenses = Object.keys(expenseGroupBy).map((key) => expenseGroupBy[key]);
  return listExpenses;
};

const getFileExtension = (fileName: string) => fileName.split(".").pop().toLowerCase() as string;
const detectUrlString = (string: string) => /^(http|https):\/\/.*/.test(string);
const iconTypeExtension = (type: string) => {
  switch (type) {
    case "xls":
    case "xlst":
    case "xlsm":
    case "xlsx": {
      return IconExcel;
    }
    case "doc":
    case "docx": {
      return IconDoc;
    }
    case "pdf": {
      return IconPDF;
    }
  }
};
const isUserMod = (user: TUserInfo) => {
  return user?.group?.role === "mod";
};
const convertDateToISOFormat = (date: Dayjs) => {
  return date.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
};

const formatTime = (date: string | Date | Dayjs, format = "DD/MM/YYYY") => {
  if (!date) {
    return "";
  }
  return dayjs(date).format(format);
};

const clampReanimated = (value: SharedValue<number>, lowerBound: number, upperBound: number) => {
  "worklet";
  return Math.min(Math.max(lowerBound, value), upperBound);
};

const formatTransactionDate = (date: string) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("DD/MM/YYYY");
};
const formatCardNumber = (prefixCardNumber, suffixCardNumber) => {
  return `${prefixCardNumber?.substring(0, 4)} ${prefixCardNumber?.substring(4, 6)}** **** ${suffixCardNumber}`;
};
const convertViToEn = (str: string) => {
  if (!str) {
    return "";
  }
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(/đ/g, "d");
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

const saveRecentCompanyTeamToStorage = async ({
  companyId,
  employeeId,
  companyTeam,
}: {
  companyId: string;
  employeeId: string;
  companyTeam: { companyTeamId: string; code: string; name?: string; nameEn?: string };
}) => {
  const companyTeamsRecent = await AsyncStorage.getItem(`${Keys.RECENT_COMPANY_TEAM}${companyId}${employeeId}`);
  let companyTeamSelected = [];
  if (companyTeamsRecent && Array.isArray(JSON.parse(companyTeamsRecent))) {
    companyTeamSelected = JSON.parse(companyTeamsRecent)?.filter((i) => i?.teamId !== companyTeam?.companyTeamId);
  }
  companyTeamSelected.unshift({
    ...companyTeam,
    teamId: companyTeam.companyTeamId,
    teamType: {
      teamTypeId: Keys.RECENT_COMPANY_TEAM,
    },
  });
  await AsyncStorage.setItem(
    `${Keys.RECENT_COMPANY_TEAM}${companyId}${employeeId}`,
    JSON.stringify(companyTeamSelected.slice(0, 3))
  );
};
const getTitleFromCategory = (category: { title?: string; titleEn?: string; title_en?: string }) => {
  const { title, titleEn, title_en } = category || {};
  if (!title && !titleEn && !title_en) {
    return "";
  }
  if (i18n.language === LanguageStatus.VN) {
    return title;
  }
  return titleEn || title_en || title;
};

const getTitleFromLocation = (location: {
  name?: string;
  nameEn?: string;
  parent?: { name?: string; nameEn?: string };
}) => {
  const { name, nameEn, parent } = location || {};
  if (!name && !nameEn) {
    return "";
  }
  if (i18n.language === LanguageStatus.VN) {
    return parent ? `${name}, ${parent?.name}` : name;
  }
  return parent ? `${nameEn || name}, ${parent?.nameEn || parent?.name}` : nameEn || name;
};

const getTitleFromAirport = (airport: { code?: string; name?: string; nameEn?: string }) => {
  const { code, name, nameEn } = airport || {};
  if (!name && !nameEn) {
    return "";
  }
  if (i18n.language === LanguageStatus.VN) {
    return `${code} - ${name}`;
  }
  return `${code} - ${nameEn || name}`;
};

const getTitleFromCompanyTeam = ({ code, name, nameEn }: { code?: string; name?: string; nameEn?: string }) => {
  if (i18n.language === LanguageStatus.VN) {
    return code ? `${code} - ${name}` : name;
  }
  return code ? `${code} - ${nameEn || name}` : nameEn || name;
};
const fromNow = (date: Date) => {
  if (!date) {
    return "";
  }
  return dayjs(date).fromNow().replace("một", "1");
};
const removeDuplicateObjects = (array: Record<string, any>[], property: string) => {
  const uniqueIds = [];
  return array.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[property]);
    if (!isDuplicate) {
      uniqueIds.push(element[property]);
      return true;
    }
    return false;
  });
};
const formatMoneyString = (value: number, fullUnit = false): string => {
  const billionUnit = fullUnit ? i18n.t("common:billion_full") : i18n.t("common:billion");
  const millionUnit = fullUnit ? i18n.t("common:million_full") : i18n.t("common:million");
  const hundredUnit = fullUnit ? i18n.t("common:hundred_full") : i18n.t("common:hundred");
  let tmp = "";
  // Nine Zeroes for Billions
  if (Math.abs(Number(value)) >= 1.0e9 || value >= 999500000) {
    tmp = (Math.abs(Number(value)) / 1.0e9).toFixed(1).split(".").join(",") + billionUnit;
  } else if (Math.abs(Number(value)) >= 1.0e6 || value >= 999500) {
    tmp = (Math.abs(Number(value)) / 1.0e6).toFixed(1).split(".").join(",") + millionUnit;
  } else if (Math.abs(Number(value)) >= 1.0e3 || value >= 995) {
    tmp = (Math.abs(Number(value)) / 1.0e3).toFixed(1).split(".").join(",") + hundredUnit;
  } else {
    tmp = `${formatMoney(value)} ₫`;
  }
  return value < 0 ? `-${tmp.replace(",0", "")}` : tmp.replace(",0", "");
};
const convertToNearest = (num) => {
  let mul = 1;
  while (num > mul * 10) {
    mul = mul * 10;
  }
  return Math.ceil(num / mul) * mul;
};
const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) {
    return "0b";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "M", "G", "T", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

const startLayoutAnimation = () => {
  if (Platform.OS === "android") {
    LayoutAnimation.configureNext({
      duration: 250,
      update: {
        property: LayoutAnimation.Properties.opacity,
        type: LayoutAnimation.Types.easeInEaseOut,
      },
      delete: {
        property: LayoutAnimation.Properties.opacity,
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
  } else {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }
};
const calculatePercentage = (previous: number, current: number): number => {
  if (previous === 0 && current === 0) {
    return 0;
  }
  if (previous === 0 && current !== 0) {
    return 100;
  }
  return Math.floor(Math.abs(((current - previous) / previous) * 100));
};

const calculateDurationInDays = (fromDate: string | Dayjs, toDate: string | Dayjs) => {
  const from = dayjs(fromDate).startOf("day");
  const to = dayjs(toDate).endOf("day");
  const rs = Math.ceil(to.diff(from, "day", true)) - 1;
  return rs < 0 ? 0 : rs;
};

type GetTitleI18nArgs = {
  vi: string;
  en?: string;
};
const getTitleI18n = ({ vi, en }: GetTitleI18nArgs) => {
  if (!en) {
    return vi ?? "";
  }

  return i18n.language === LanguageStatus.VN ? vi : en ?? "";
};
const getWarningMessageTravelDateIfExist = (
  periods: MobileExpExpenseRequestTravelUsageQuery["expExpenseRequestTravelUsage"]["periods"],
  fromDate: Date | string,
  toDate: Date | string
) => {
  const newTravelUsages = periods?.map((item, index) => {
    let numOfDays = 0;
    if (item?.period === "monthly") {
      const isSameMonth = dayjs(fromDate).isSame(toDate, "month") && dayjs(fromDate).isSame(toDate, "year");
      if (isSameMonth) {
        numOfDays = countNumberOfDays(fromDate, toDate);
      } else {
        const isFirstMonth = index === 0;
        const isLastMonth = index === periods?.length - 1;
        numOfDays = isFirstMonth
          ? countNumberOfDays(fromDate, dayjs(fromDate).endOf("month"))
          : isLastMonth
          ? countNumberOfDays(dayjs(toDate).startOf("month"), toDate)
          : countNumberOfDays(
              dayjs(fromDate).add(index, "month").startOf("month"),
              dayjs(fromDate).add(index, "month").endOf("month")
            );
      }
    } else {
      const isSameQuarter = dayjs(fromDate).isSame(toDate, "quarter") && dayjs(fromDate).isSame(toDate, "year");
      if (isSameQuarter) {
        numOfDays = countNumberOfDays(fromDate, toDate);
      } else {
        const isFirstQuarter = index === 0;
        const isLastQuarter = index === periods?.length - 1;
        numOfDays = isFirstQuarter
          ? countNumberOfDays(fromDate, dayjs(fromDate).endOf("quarter"))
          : isLastQuarter
          ? countNumberOfDays(dayjs(toDate).startOf("quarter"), toDate)
          : countNumberOfDays(
              dayjs(fromDate).add(index, "quarter").startOf("quarter"),
              dayjs(fromDate).add(index, "quarter").endOf("quarter")
            );
      }
    }
    return { ...item, totalTravelDays: item?.totalTravelDays + numOfDays };
  });
  const travelUsageOver = newTravelUsages?.filter((item) => item?.totalTravelDays > item?.maxTravelDays);
  if (!travelUsageOver?.length) {
    return null;
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //const quarters = ["The 1st quarter", "Second quarter", "Third quarter", "Fourth quarter"];
  return `${i18n.t("common:over_travel_remain")} ${travelUsageOver
    ?.map((item) => {
      const dayText =
        item?.period === "monthly"
          ? i18n.t("common:over_travel_remain_month", {
              day: item?.totalTravelDays - item?.maxTravelDays,
              month: i18n.language === LanguageStatus.EN ? months[item?.periodValue] : item?.periodValue,
            })
          : i18n.t("common:over_travel_remain_quarter", {
              day: item?.totalTravelDays - item?.maxTravelDays,
              quarter: item?.periodValue,
            });
      return `${dayText}`;
    })
    .join(", ")}`;
};
const getPolicyTextBooking = (data: FlightBookingPolicy | TransportationPolicy | HotelPolicy, t: any) => {
  const policies = [];
  const locationPolicies = (data as any).locationPolicies ?? (data as any).locationPolices;
  if (locationPolicies) {
    // Hạn mức đặt phòng hoặc vé
    const unit = {
      [BOOKING_TYPE.HOTEL]: t("night"),
      [BOOKING_TYPE.FLIGHT]: t("trip"),
      [BOOKING_TYPE.TRANSPORTATION]: t("trip"),
    }[data.type];
    locationPolicies.forEach((p) => {
      policies.push(
        `-- ${t("limited_amount")}: ${formatMoney(p.amount)} (₫) / ${unit} ${t("for_location")} ${getTitleI18n({
          vi: p.location.name,
          en: p.location.nameEn,
        })}`
      );
    });
  }

  // Thời gian đặt phòng hoặc vé
  const entry = {
    [BOOKING_TYPE.HOTEL]: t("room"),
    [BOOKING_TYPE.FLIGHT]: t("ticket"),
    [BOOKING_TYPE.TRANSPORTATION]: t("ticket"),
  }[data.type];

  if (!!data.minAdvanceBookingDays && !!data.maxAdvanceBookingDays) {
    policies.push(
      `-- ${t("booking_interval", { entry })}: ${dayjs(data.travelDay)
        .subtract(data.maxAdvanceBookingDays, "day")
        .format("DD/MM/YYYY")} - ${dayjs(data.travelDay)
        .subtract(data.minAdvanceBookingDays, "day")
        .format("DD/MM/YYYY")}`
    );
  }

  if (!!data.minAdvanceBookingDays && !data.maxAdvanceBookingDays) {
    policies.push(
      `-- ${t("booking_interval", { entry })} ${t("booking_interval_before")}: ${dayjs(data.travelDay)
        .subtract(data.minAdvanceBookingDays, "day")
        .format("DD/MM/YYYY")}`
    );
  }

  if (!data.minAdvanceBookingDays && !!data.maxAdvanceBookingDays) {
    policies.push(
      `-- ${t("booking_interval", { entry })} ${t("booking_interval_after")}: ${dayjs(data.travelDay)
        .subtract(data.maxAdvanceBookingDays, "day")
        .format("DD/MM/YYYY")}`
    );
  }

  // Loại phòng hoặc hạng ghế
  switch (data.type) {
    case BOOKING_TYPE.FLIGHT:
      data.maxSeatLevel &&
        policies.push(
          `-- ${t("max_seat_level")}: ${
            {
              1: t("economy"),
              2: t("deluxe_economy"),
              3: t("business"),
              4: t("first"),
              5: t("suite"),
            }[data.maxSeatLevel]
          }`
        );
      break;
    case BOOKING_TYPE.HOTEL:
      data.maxHotelLevel &&
        policies.push(
          `-- ${t("max_hotel_level")}: ${
            {
              1: t("1_star"),
              2: t("2_star"),
              3: t("3_star"),
              4: t("4_star"),
              5: t("5_star"),
            }[data.maxHotelLevel]
          }`
        );
      break;
  }
  return Platform.OS === "web" ? policies.join("%0D%0A") : policies.join("\n");
};

const isNilOrEmpty = (value) => {
  if ([value === null, value === undefined, typeof value === "string" && !value].some(Boolean)) {
    return true;
  }

  return false;
};

const measureLayoutSync = (
  viewRef: ReactNode,
  containerRef: ReactNode
): Promise<{ x: number; y: number; width: number; height: number }> => {
  return new Promise((resolve) => {
    viewRef.measureLayout(containerRef, (x, y, width, height) => {
      resolve({ x, y, width, height });
    });
  });
};

type Point = { x: number; y: number };
const twoDimensionalDistance = (a: Point, b: Point) => {
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  return Math.sqrt(dx * dx + dy * dy);
};

const convertNameEnToVi = (vietnameseName) => {
  const vietnameseToEnglishMap = {
    á: "a",
    à: "a",
    ả: "a",
    ã: "a",
    ạ: "a",
    ă: "a",
    ắ: "a",
    ằ: "a",
    ẳ: "a",
    ẵ: "a",
    ặ: "a",
    â: "a",
    ấ: "a",
    ầ: "a",
    ẩ: "a",
    ẫ: "a",
    ậ: "a",
    đ: "d",
    é: "e",
    è: "e",
    ẻ: "e",
    ẽ: "e",
    ẹ: "e",
    ê: "e",
    ế: "e",
    ề: "e",
    ể: "e",
    ễ: "e",
    ệ: "e",
    í: "i",
    ì: "i",
    ỉ: "i",
    ĩ: "i",
    ị: "i",
    ó: "o",
    ò: "o",
    ỏ: "o",
    õ: "o",
    ọ: "o",
    ô: "o",
    ố: "o",
    ồ: "o",
    ổ: "o",
    ỗ: "o",
    ộ: "o",
    ơ: "o",
    ớ: "o",
    ờ: "o",
    ở: "o",
    ỡ: "o",
    ợ: "o",
    ú: "u",
    ù: "u",
    ủ: "u",
    ũ: "u",
    ụ: "u",
    ư: "u",
    ứ: "u",
    ừ: "u",
    ử: "u",
    ữ: "u",
    ự: "u",
    ý: "y",
    ỳ: "y",
    ỷ: "y",
    ỹ: "y",
    ỵ: "y",
  };
  return vietnameseName
    .split("")
    .map((char) => vietnameseToEnglishMap[char.toLowerCase()] || char)
    .join("");
};

const checkFirstLaunch = async () => {
  const isFirstLaunch = await AsyncStorage.getItem(Keys.IS_FIRST_LAUNCH);
  // This key does not exist --> it's the first launch
  if (isFirstLaunch === null) {
    return true;
  }
  return false;
};

const filter = (field, condition, otherwise = undefined) => {
  return !isEmpty(field) || isNumber(field) || isBoolean(field) ? condition(field) : otherwise;
};

const getCurrentCompanyId = async () => {
  if (Platform.OS === "web" && sessionStorage.getItem(Keys.COMPANY_ID)) {
    return sessionStorage.getItem(Keys.COMPANY_ID);
  }
  return AsyncStorage.getItem(Keys.COMPANY_ID);
};

const saveCurrentCompanyId = async (companyId) => {
  if (Platform.OS === "web") {
    await sessionStorage.setItem(Keys.COMPANY_ID, companyId);
  }
  await AsyncStorage.setItem(Keys.COMPANY_ID, companyId);
};

const removeCurrentCompanyId = async () => {
  if (Platform.OS === "web") {
    await sessionStorage.removeItem(Keys.COMPANY_ID);
  }
  await AsyncStorage.removeItem(Keys.COMPANY_ID);
};

const getCurrentUser = async () => {
  if (Platform.OS === "web" && sessionStorage.getItem(Keys.USER_INFO)) {
    return JSON.parse(sessionStorage.getItem(Keys.USER_INFO));
  }
  const user = await AsyncStorage.getItem(Keys.USER_INFO);
  return user ? JSON.parse(user) : null;
};

export const convertCurrencyStringToRawValue = (formattedValue: string): number => {
  const numericString = formattedValue.replace(/\./g, "").replace(",", ".");
  const numericValue = parseFloat(numericString);
  return isNaN(numericValue) ? 0 : numericValue;
};

const saveCurrentUser = async (user) => {
  if (Platform.OS === "web") {
    await sessionStorage.setItem(Keys.USER_INFO, JSON.stringify(user));
  }
  await AsyncStorage.setItem(Keys.USER_INFO, JSON.stringify(user));
};

const removeCurrentUser = async () => {
  if (Platform.OS === "web") {
    await sessionStorage.removeItem(Keys.USER_INFO);
  }
  await AsyncStorage.removeItem(Keys.USER_INFO);
};

const getFileNameFromUrl = (url) => {
  if (!url) {
    return "";
  }
  try {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const fileName = pathname.split("/").pop();
    return fileName || "";
  } catch (error) {
    console.error("Invalid URL:", error);
    return "";
  }
};
const convertStringToNumber = (value: string) => {
  if (!value) {
    return 0;
  }
  if (isNumber(value)) {
    return value;
  }

  const sanitizedValue = value.replace(/\./g, "").replace(/,/g, ".");
  return Number(sanitizedValue) || 0;
};
const isForeignCurrency = (currency: string) => {
  if (currency && currency !== "VND") {
    return true;
  }
  return false;
};

const exchangeRateConverter = (amount: number, exchangeRate?: number) => {
  if (!exchangeRate) {
    return null;
  }
  return Math.round(amount / exchangeRate);
};

export {
  isDebugger,
  formatMoneyWithSymbol,
  getContentTypeFromFilename,
  generateDocs,
  countNumberOfDays,
  toFixedLocale,
  numberToLocaleString,
  numStringToLocale,
  formatPhone,
  formatPhoneWithCountryCode,
  alphanumCase,
  getError,
  moneyFormat,
  strToRBG,
  uploadFile,
  formatMoney,
  formatStringMoneyToNumber,
  groupExpenseByRequest,
  getFileExtension,
  detectUrlString,
  iconTypeExtension,
  /*--- new utils ---*/
  isUserMod,
  convertDateToISOFormat,
  formatTime,
  clampReanimated,
  formatTransactionDate,
  formatCardNumber,
  convertViToEn,
  saveRecentCompanyTeamToStorage,
  getTitleFromCategory,
  getTitleFromCompanyTeam,
  fromNow,
  removeDuplicateObjects,
  isValidFileUpload,
  formatMoneyString,
  convertToNearest,
  formatBytes,
  startLayoutAnimation,
  getTitleFromLocation,
  getTitleFromAirport,
  calculatePercentage,
  calculateDurationInDays,
  getTitleI18n,
  getWarningMessageTravelDateIfExist,
  getPolicyTextBooking,
  isNilOrEmpty,
  measureLayoutSync,
  formatDuration,
  getMinDate,
  convertNameEnToVi,
  twoDimensionalDistance,
  checkFirstLaunch,
  getCurrentCompanyId,
  saveCurrentCompanyId,
  removeCurrentCompanyId,
  getCurrentUser,
  saveCurrentUser,
  removeCurrentUser,
  filter,
  getFileNameFromUrl,
  convertStringToNumber,
  isForeignCurrency,
  exchangeRateConverter,
};
