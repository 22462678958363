// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import dayjs from "dayjs";
import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { VNDIcon } from "assets/images/svg/icons";
import {
  AppText,
  Button,
  CategorySelect,
  CurrencyInput,
  DatePickerCustom,
  FloatFooter,
  IconCustom,
  KeyboardAwareScrollViewCustom,
  SelectBottomCustom,
  TextInputCustom,
} from "components";
import { COMPANIES } from "constants/Companies";
import { ApprovalType, InvoiceType } from "constants/ExpenseApproval";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useAuth } from "contexts/AuthContext";
import { useIsExpenseRequestEnabled, useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { Colors, Fonts } from "theme";
import { getTitleI18n, startLayoutAnimation } from "utils";

import CompanyTeamSelect from "components/InputCustom/CompanyTeamSelect";
import UploadFiles from "components/InputCustom/UploadFiles/UploadFiles";
import { ACCOUNT_TYPES, CONSTANTS, INVOICE_TYPE } from "constants/constants";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import InvoiceSection from "screens/Expense/components/ExpenseForm/InvoiceSection/InvoiceSection";
import LocationInfoSection from "screens/Expense/components/ExpenseForm/LocationInfoSection";
import PaymentInfoSection from "screens/Expense/components/ExpenseForm/PaymentInfoSection";
import RequestSelectInput from "screens/Expense/components/ExpenseForm/RequestSelectInput";
import TransactionSection from "screens/Expense/components/ExpenseForm/TransactionSection";
import { formatExpenseFormValues } from "screens/Expense/components/ExpenseForm/helpers";
import ApproverChooser, { ApproverChooserMethods } from "../ApproverChooser/ApproverChooser";
import { ExpenseFormAction, ExpenseFormProps, ExpenseFormValues, LOCATION_TYPE } from "./types";
import useInvoiceDetailQuery from "screens/Inbox/InvoiceDetailScreen/hooks/useInvoiceDetailQuery";
import { DateModalInfoData } from "screens/ExpenseRequest/types";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { navigationRef } from "navigation/RootNavigation";
import SCREEN_NAME from "navigation/ScreenName";
import { EVENT } from "constants/Tracking";
import { useMasterData } from "contexts/MasterDataContext";
import CopilotStepCustom from "app/components/Walkthrough/CopilotStepCustom";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";
import AllocatedUploadFile from "screens/Expense/components/ExpenseForm/AllocatedUploadFile";
import { CategoryOption } from "components/InputCustom/CategorySelect";
import isEmpty from "lodash/isEmpty";
import { SelectBottomCustomItem } from "components/InputCustom/SelectBottomCustom";
import CurrencySection from "screens/Expense/components/ExpenseForm/CurrencySection";
import ForeignCurrencyInput from "components/InputCustom/ForeignCurrencyInput";

const ExpenseForm: FC<ExpenseFormProps> = ({
  allowSendReport = true,
  saveDraftText,
  isSubmitting,
  onSubmit,
  approverId,
  setApproverId,
  expenseDetail,
  setIsDirty,
  defaultValue,
  shouldShowWalkthrough = false,
}) => {
  const screenName = navigationRef.current?.getCurrentRoute()?.name;
  const approverChooserRef = useRef<ApproverChooserMethods>(null);
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");

  const { company, user } = useAuth();
  const { setting } = useMasterData();

  const [categorySubAccounts, setCategorySubAccounts] = useState<SelectBottomCustomItem[]>([]);
  const [dateModalInfo, setDateModalInfo] = useState<DateModalInfoData>({
    visible: false,
    date: undefined,
    minimumDate: undefined,
    maximumDate: undefined,
  });
  const [footerHeight, setFooterHeight] = useState(0);

  const [getInvoiceDetail] = useInvoiceDetailQuery({
    invoiceId: defaultValue?.invoiceId,
    companyId: user.company.company_id,
  });
  const isExpenseRequestEnabled = useIsExpenseRequestEnabled();

  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );
  const {
    approvalTypeReport,
    setting: { allowEditAmountExpenseWithInvoice },
  } = useMasterData();

  const [
    FEATURE_TEAM_MGMT,
    FEATURE_OPTIONAL_NOTE,
    FEATURE_OPTIONAL_CATEGORY,
    FEATURE_EXPENSE_PAYMENT_INFO,
    FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE,
  ] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_TEAM_MGMT,
    FEATURE_FLAGS.FEATURE_OPTIONAL_NOTE,
    FEATURE_FLAGS.FEATURE_OPTIONAL_CATEGORY,
    FEATURE_FLAGS.FEATURE_EXPENSE_PAYMENT_INFO,
    FEATURE_FLAGS.FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE,
  ]);

  const isRequiredPaymentInfo = setting?.requiredPaymentInfo;

  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<ExpenseFormValues>({
    shouldFocusError: false,
    defaultValues: {
      amount: defaultValue?.amount?.toString() ?? "",
      companyTeam: defaultValue?.companyTeam,
      expenseDate: new Date().toISOString(),
      placeDetails: {
        type: LOCATION_TYPE.NONE,
      },
      /*--- flow create expense from Camera Screen after take a photo ---*/
      invoice: {
        invoiceType: expenseDetail ? expenseDetail?.invoiceType : defaultValue?.invoiceType ?? InvoiceType.ELECTRONIC,
        invoiceElectronic: null, // flow create expense from Invoice Screen
      },
      invoiceFiles: defaultValue?.photos ?? [],
      fileAttachments: defaultValue?.attachments ?? [],
      /*--- end ---*/
      /*--- flow create expense from card transaction ---*/
      cardTransaction: expenseDetail?.expenseCardTransactions?.[0]?.cardTransaction
        ? { isOn: true, ...expenseDetail.expenseCardTransactions[0].cardTransaction }
        : defaultValue?.cardTransaction
        ? { isOn: true, ...defaultValue.cardTransaction }
        : undefined,
      /*--- end ---*/
      paymentInfo: {
        type: isRequiredPaymentInfo ? ACCOUNT_TYPES.PERSONAL : ACCOUNT_TYPES.NONE,
      },
    },
    mode: CONSTANTS.COMMON.VALIDATE_RULE,
  });

  /*-- Set default value for payment info type when isRequiredPaymentInfo changes to avoid the issue where the default value cannot be set upon reloading the web app. --*/
  useEffect(() => {
    setValue("paymentInfo.type", isRequiredPaymentInfo ? ACCOUNT_TYPES.PERSONAL : ACCOUNT_TYPES.NONE);
  }, [isRequiredPaymentInfo]);
  /*-- end --*/

  const currentPaymentAccountType = watch("paymentInfo.type");
  const companyTeam = watch("companyTeam");
  const expenseRequestId = watch("request")?.expenseRequestId;
  const cardTransaction = watch("cardTransaction");
  const expenseDate = watch("expenseDate");
  const amount = watch("amount");
  const invoice = watch("invoice");
  const isEnableCurrency = watch("currency.isOn");

  const getInvoiceInfo = async () => {
    const invoiceRes = await getInvoiceDetail();

    const invoiceData = invoiceRes?.data?.expInvoiceDetail;
    if (invoiceData) {
      if (!cardTransaction) {
        setValue("amount", invoiceData.totalAmountWithVat?.toString());
        setValue("expenseDate", invoiceData.issuedDate);
      }
      setValue("invoice", {
        invoiceType: InvoiceType.ELECTRONIC,
        invoiceElectronic: invoiceData as ExpenseFormValues["invoice"]["invoiceElectronic"],
      });
    }
  };

  /*-- start walkthrough flow --*/
  const { start, steps } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) =>
      steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME &&
      steps[key].order === CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step2
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          suppliedScrollView: scrollViewRef.current,
          step: step,
          walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);
  /*-- end --*/

  /*-- flow create expense from Invoice Screen --*/
  useEffect(() => {
    if (defaultValue?.invoiceId) {
      getInvoiceInfo();
    }
  }, [defaultValue?.invoiceId]);

  //Update expense vatRate whenever invoice changed
  useEffect(() => {
    setValue(
      "vatRate",
      invoice?.invoiceElectronic?.vatRate && invoice.invoiceType === InvoiceType.ELECTRONIC
        ? invoice.invoiceElectronic.vatRate
        : 0
    );
  }, [invoice.invoiceType, invoice.invoiceElectronic]);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  useEffect(() => {
    /*--- flow update ---*/
    if (expenseDetail) {
      const formValues = formatExpenseFormValues({ expenseDetail, isRequiredPaymentInfo });
      reset(formValues, { keepDirtyValues: true });

      if (!isEmpty(expenseDetail?.expenseCategory?.subAccounts)) {
        setCategorySubAccounts(
          expenseDetail.expenseCategory.subAccounts.map((item) => ({
            label: item.title,
            value: { title: item.title, accountCode: item.accountCode },
            leftIcon: <IconCustom name="sell-outline" />,
          }))
        );
      }
    }
  }, [expenseDetail]);

  const onSubmitForm = (type: ExpenseFormAction) => (data: ExpenseFormValues) => {
    if (type === ExpenseFormAction.SUBMIT_REPORT && approvalTypeReport === ApprovalType.MANUAL) {
      approverChooserRef.current?.present();
      return;
    }
    onSubmit(type, data);
  };
  /*-- scroll to first error when user submit form --*/
  const scrollViewRef = useRef<any>();
  const positionInput = useRef({});
  const handleSetPositionInput = (key) => (offsetY) => {
    positionInput.current[key] = offsetY;
  };
  const onError: SubmitErrorHandler<ExpenseFormValues> = (errors) => {
    const fieldFirstError = Object.keys(errors)?.[0];
    if (fieldFirstError) {
      scrollViewRef?.current?.scrollTo({
        y: positionInput?.current?.[fieldFirstError] ?? 0,
        animated: true,
      });
    }
    return errors;
  };
  /*--- end ---*/
  const onSubmitReportFormApproverModal = (data: ExpenseFormValues) => {
    onSubmit(ExpenseFormAction.SUBMIT_REPORT, data);
  };

  const onChangeCategory = (category: CategoryOption) => {
    startLayoutAnimation();
    if (category?.subAccounts?.length) {
      setCategorySubAccounts(
        category.subAccounts.map((item) => ({
          label: item.title,
          value: { title: item.title, accountCode: item.accountCode },
          leftIcon: <IconCustom name="sell-outline" />,
        }))
      );
    } else {
      setValue("categorySubAccount", null);
      setCategorySubAccounts([]);
    }
  };

  const expenseHaveCardTransaction = Boolean(cardTransaction?.cardTransactionId) && cardTransaction?.isOn;
  const disabledEditAmountExpenseWithInvoice =
    invoice?.invoiceType === INVOICE_TYPE.E_INVOICE && invoice?.invoiceElectronic && !allowEditAmountExpenseWithInvoice;

  return (
    <>
      <View style={styles.avoidViewStyle}>
        <KeyboardAwareScrollViewCustom
          innerRef={(ref) => (scrollViewRef.current = ref)}
          contentContainerStyle={[styles.contentContainer, { paddingBottom: footerHeight + 8 }]}
        >
          {setting?.isSupportCurrency && (
            <View style={styles.section}>
              <CurrencySection
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                getValues={getValues}
              />
            </View>
          )}
          <CopilotStepCustom
            title={"walkthrough_expense_title_step2"}
            text={"walkthrough_expense_description_step2"}
            order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step2}
            image={BizziBotSticker01}
            walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
            offsetX={10}
            autoScroll={true}
          >
            <View style={styles.section}>
              <InvoiceSection
                isExpenseFromCardTransaction={expenseHaveCardTransaction}
                errors={errors}
                name="invoice"
                control={control}
                setValue={setValue}
                watch={watch}
                expenseReportId={expenseDetail?.expenseReport?.expenseReportId}
              />
            </View>
          </CopilotStepCustom>

          {setting?.isCardEnabled && (
            <>
              <View style={styles.section}>
                <TransactionSection
                  name="cardTransaction"
                  expenseId={expenseDetail?.expenseId}
                  control={control}
                  amount={amount}
                  setAmount={(value) => setValue("amount", value)}
                  expenseDate={expenseDate}
                  setExpenseDate={(date) => setValue("expenseDate", date)}
                />
              </View>
            </>
          )}
          <View style={[styles.section, { gap: 12 }]}>
            <AppText style={StyleSheet.flatten(Fonts.SentenceSubtileXLarge)}>{t("header_text")}</AppText>
            {isEnableCurrency && (
              <ForeignCurrencyInput
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput("currencyAmount")}
                disabled={disabledEditAmountExpenseWithInvoice || expenseHaveCardTransaction}
                name="foreignTotalAmountWithVat"
                placeholder="0"
                right={<IconCustom name="attach-money" width={26} height={26} fill="#94A3B8" />}
                control={control}
                rules={{
                  required: t("required"),
                }}
                error={errors.foreignTotalAmountWithVat}
                label={t("currency_amount")}
                onChange={(amount) => {
                  const exchangeRate = getValues("currency.exchangeRate");
                  setValue("amount", amount * exchangeRate);
                }}
              />
            )}
            <CopilotStepCustom
              title={"walkthrough_expense_title_step3"}
              text={"walkthrough_expense_description_step3"}
              order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step3}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
              borderRadius={10}
              offsetY={5}
              offsetX={-3}
              autoScroll={true}
            >
              <CurrencyInput
                keyboardType={Platform.OS === "ios" ? "numbers-and-punctuation" : "numeric"}
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput("amount")}
                disabled={disabledEditAmountExpenseWithInvoice || expenseHaveCardTransaction || isEnableCurrency}
                name="amount"
                placeholder="0"
                right={<VNDIcon />}
                control={control}
                rules={{
                  required: t("required"),
                }}
                error={errors.amount}
                label={expenseHaveCardTransaction ? t("expense_transaction_amount") : t("total_amount_with_vat")}
              />
            </CopilotStepCustom>
            <CopilotStepCustom
              title={"walkthrough_expense_title_step4"}
              text={"walkthrough_expense_description_step4"}
              order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step4}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
              borderRadius={10}
              autoScroll={true}
              offsetY={5}
              offsetX={-3}
            >
              <CategorySelect
                style={styles.input}
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput("category")}
                name="category"
                control={control}
                error={errors.category}
                rules={FEATURE_OPTIONAL_CATEGORY ? {} : { required: t("required") }}
                partnerTaxCode={invoice?.invoiceElectronic?.sellerTaxCode}
                onChange={onChangeCategory}
                defaultCategoryTitle={getTitleI18n({
                  vi: expenseDetail?.expenseCategory?.title,
                  en: expenseDetail?.expenseCategory?.titleEn,
                })}
              />
            </CopilotStepCustom>
            {(!isEmpty(categorySubAccounts) || getValues("categorySubAccount.accountCode")) && (
              <SelectBottomCustom
                snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
                label={t("category_sub_account")}
                name="categorySubAccount"
                control={control}
                options={categorySubAccounts}
                rules={{ required: t("required") }}
                error={errors?.categorySubAccount}
                setPosition={handleSetPositionInput("categorySubAccount")}
              />
            )}
            {FEATURE_TEAM_MGMT && !isTLNExpenseCompany && (
              <CopilotStepCustom
                title={"walkthrough_expense_title_step5"}
                text={"walkthrough_expense_description_step5"}
                order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step5}
                image={BizziBotSticker01}
                walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
                autoScroll={true}
                borderRadius={10}
                offsetY={5}
                offsetX={-3}
              >
                <CompanyTeamSelect
                  style={styles.input}
                  disabled={Boolean(expenseDetail) && Boolean(expenseDetail?.expenseReport?.expenseReportId)}
                  autoFillCompanyDefault={!expenseDetail && !defaultValue?.companyTeamId}
                  onClear={() => {
                    setValue("companyTeam", null);
                    setValue("request", null);
                  }}
                  name="companyTeam"
                  control={control}
                  setValue={setValue}
                  onSelect={() => {
                    setValue("request", null);
                  }}
                />
              </CopilotStepCustom>
            )}
            {isExpenseRequestEnabled && !isTLNExpenseCompany && (
              <CopilotStepCustom
                title={"walkthrough_expense_title_step6"}
                text={"walkthrough_expense_description_step6"}
                order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step6}
                image={BizziBotSticker01}
                walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
                autoScroll={true}
                borderRadius={10}
                offsetY={5}
              >
                <RequestSelectInput
                  style={styles.input}
                  label={t("request")}
                  selectedCompanyTeamId={companyTeam?.companyTeamId}
                  name="request"
                  control={control}
                  error={errors.request}
                  onClear={() => setValue("request", null)}
                  value={expenseRequestId}
                />
              </CopilotStepCustom>
            )}
            <CopilotStepCustom
              title={"walkthrough_expense_title_step7"}
              text={"walkthrough_expense_description_step7"}
              order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step7}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
              autoScroll={true}
              borderRadius={10}
              offsetY={5}
              offsetX={-3}
              style={{ gap: 12 }}
            >
              <TextInputCustom
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput("note")}
                name="note"
                control={control}
                error={errors.note}
                label={t("explanation_placeholder")}
                placeholder={t("explanation_placeholder")}
                multiline
                autoHeight
                rules={
                  FEATURE_OPTIONAL_NOTE
                    ? { maxLength: { value: 1000 } }
                    : {
                        required: t("required"),
                        maxLength: { value: 1000 },
                      }
                }
              />
              <DatePickerCustom
                disabled={expenseHaveCardTransaction}
                name="expenseDate"
                control={control}
                label={expenseHaveCardTransaction ? t("transaction_date") : t("created_date")}
                placeholder={t("created_date")}
                onPress={() =>
                  setDateModalInfo({
                    visible: true,
                    date: getValues("expenseDate"),
                  })
                }
              />
            </CopilotStepCustom>
          </View>
          {FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE && (
            <AllocatedUploadFile
              error={errors?.allocatedExpense?.allocatedDocuments}
              control={control}
              setValue={setValue}
              watch={watch}
              containerRef={scrollViewRef}
              setPosition={handleSetPositionInput("allocatedExpense")}
            />
          )}
          <CopilotStepCustom
            title={"walkthrough_expense_title_step8"}
            text={"walkthrough_expense_description_step8"}
            order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step8}
            image={BizziBotSticker01}
            walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
            autoScroll={true}
            borderRadius={10}
            offsetX={10}
            style={{ gap: 8 }}
          >
            <LocationInfoSection
              containerRef={scrollViewRef}
              setPosition={handleSetPositionInput("placeDetails")}
              control={control}
              setValue={setValue}
              errors={errors}
              watch={watch}
              expenseReportId={expenseDetail?.expenseReport?.expenseReportId}
            />
            {FEATURE_EXPENSE_PAYMENT_INFO && !expenseHaveCardTransaction && (
              <PaymentInfoSection
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput("paymentInfo")}
                currentType={currentPaymentAccountType}
                getValues={getValues}
                setValue={setValue}
                name="paymentInfo"
                control={control}
                errors={errors}
                watch={watch}
              />
            )}
          </CopilotStepCustom>
          <View style={styles.section}>
            <AppText style={[Fonts.SentenceSubtileXLarge, styles.titleSection]}>{t("other_papers")}</AppText>
            <UploadFiles title={t("other_papers")} name="fileAttachments" control={control} />
          </View>
        </KeyboardAwareScrollViewCustom>
        <FloatFooter onLayout={onLayoutFooter}>
          <View style={styles.container}>
            {allowSendReport && (
              <Button
                type="secondary"
                disabled={isSubmitting}
                style={styles.buttonSubmit}
                onPress={handleSubmit(onSubmitForm(ExpenseFormAction.SUBMIT_REPORT), onError)}
              >
                {t("submit")}
              </Button>
            )}
            <Button
              type="primary"
              eventName={
                {
                  [SCREEN_NAME.NewExpenseScreen]: EVENT.EXPENSE.CREATED,
                  [SCREEN_NAME.EditExpenseScreen]: EVENT.EXPENSE.UPDATED,
                }[screenName]
              }
              disabled={isSubmitting}
              onPress={handleSubmit(onSubmitForm(ExpenseFormAction.CREATE_OR_UPDATE_EXPENSE), onError)}
              style={styles.buttonSave}
            >
              {saveDraftText}
            </Button>
          </View>
        </FloatFooter>
      </View>
      <ApproverChooser
        ref={approverChooserRef}
        approverId={approverId}
        onSubmit={handleSubmit(onSubmitReportFormApproverModal)}
        onApproverId={(approverId: string) => {
          setApproverId(approverId);
        }}
      />
      <DateTimePickerModal
        isVisible={dateModalInfo?.visible}
        minimumDate={dayjs().subtract(50, "years").toDate()}
        maximumDate={dayjs().toDate()}
        onConfirm={(date) => {
          setDateModalInfo((prevState) => ({ ...prevState, visible: false, date }));
          setValue("expenseDate", new Date(date) as any, {
            shouldValidate: true,
          });
        }}
        date={getValues("expenseDate") ? dayjs(getValues("expenseDate")).toDate() : new Date()}
        display="inline"
        onCancel={() => setDateModalInfo((prevState) => ({ ...prevState, visible: false }))}
        accentColor={Colors.primary50}
        locale={i18n.language === LanguageStatus.VN ? "vi-VN" : "en-US"}
        confirmTextIOS={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t("common:confirm")
        }
        cancelTextIOS={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t("common:cancel")
        }
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    paddingTop: 6,
  },
  buttonSubmit: {
    flex: 1,
    marginRight: 6,
  },
  buttonSave: {
    flex: 1,
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    gap: 6,
  },
  titleSection: {
    paddingBottom: 20,
  },
  section: {
    backgroundColor: Colors.while,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  avoidViewStyle: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: Colors.grayscale05,
  },
  contentContainer: { gap: 8 },
});

export default memo(ExpenseForm);
