import { MobileExpenseReportDetailsQuery } from "types/generated";

export const ExpenseHelper = {
  /**
   * Calculates various expense-related amounts from a list of expenses
   * @param expenses - Array of expenses from expense report details
   * @param exchangeRate - Optional exchange rate to convert foreign amounts
   * @returns Object containing:
   * - totalAmountWithVat: Sum of all expense amounts including VAT
   * - totalCashAdvanceAmount: Sum of all cash advances
   * - totalCardTransactionAmount: Sum of all card transactions
   * - paidAmount: Total amount already paid (cash advances + card transactions)
   * - payableAmount: Remaining amount to be paid (total - paid amount)
   */
  getExpensesRelevantAmounts: (
    expenses: MobileExpenseReportDetailsQuery["expExpenseReportDetails"]["expenses"],
    exchangeRate?: number
  ): {
    totalAmountWithVat: number;
    totalCashAdvanceAmount: number;
    totalCardTransactionAmount: number;
    paidAmount: number;
    payableAmount: number;
  } => {
    if (!expenses) {
      return {
        totalAmountWithVat: 0,
        totalCashAdvanceAmount: 0,
        totalCardTransactionAmount: 0,
        paidAmount: 0,
        payableAmount: 0,
      };
    }
    let totalAmountWithVat = 0;
    let totalCashAdvanceAmount = 0;
    let totalCardTransactionAmount = 0;

    const expenseRequestIds = new Set<string>();
    expenses.forEach((expense) => {
      // Calculate total cash advance amount
      if (!expenseRequestIds.has(expense.expenseRequestId)) {
        totalCashAdvanceAmount += expense.expenseRequest?.cashAdvances?.[0]?.amount || 0;
        expenseRequestIds.add(expense.expenseRequestId);
      }

      if (expense.expenseCardTransactions) {
        totalCardTransactionAmount += Math.abs(expense.expenseCardTransactions[0]?.cardTransaction?.amount || 0);
      }
      totalAmountWithVat += expense.exchangeRate
        ? expense.foreignTotalAmountWithVat * (exchangeRate ?? expense.exchangeRate)
        : expense.totalAmountWithVat;
    });

    const paidAmount = totalCashAdvanceAmount + totalCardTransactionAmount;
    const payableAmount = Math.abs(totalAmountWithVat - paidAmount);

    return {
      totalAmountWithVat,
      totalCashAdvanceAmount,
      totalCardTransactionAmount,
      paidAmount,
      payableAmount,
    };
  },

  /**
   * Extracts foreign expense currency and exchange rate information from an array of expenses
   * @param expenses Array of expense objects
   * @returns Object containing:
   * - currency: The currency code (VND or USD) of foreign expenses
   * - exchangeRate: The exchange rate for foreign expenses, or -1 if no foreign expenses
   */
  getForeignExpenseInfo: (expenses: any[]): CurrencyExchange => {
    const foreignExpenses = expenses.filter((expense) => expense.exchangeRate);
    if (foreignExpenses.length === 0) {
      return {
        currency: "VND",
        exchangeRate: -1,
      };
    }

    return {
      currency: foreignExpenses[0].currency,
      exchangeRate: foreignExpenses[0].exchangeRate,
    };
  },
};
