import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";

interface IEXPENSE_CATEGORY_ICON {
  [key: string]: string;
}
const __PROD__ = !["dev", "uat", "staging"].includes(process.env.APP_ENV);
const __PRE_PROD__ = process.env.VERCEL_BRANCH_URL?.includes("preprod-bizzi.vercel.app");

const isProduction = __PROD__ || __PRE_PROD__;
// const isProduction = false;

export const getAppVersion = () => {
  const versionIndicators = [];
  if (!isProduction) {
    versionIndicators.push("UAT");
  }
  if (process.env.GIT_COMMIT_SHA) {
    versionIndicators.push(`${process.env.GIT_COMMIT_SHA}`);
  }

  return Platform.select({
    web: versionIndicators.join("."),
    default: `${DeviceInfo.getVersion()}.${versionIndicators.join(".")}`,
  });
};

export default {
  IS_PRODUCTION: isProduction,
  SPEND_ENDPOINT: isProduction ? "https://spend.bizzi.vn" : "https://spend-uat.bizzi.vn",
  BIZZI_ID_ENDPOINT: isProduction ? "https://id.bizzi.services" : "https://id-uat.bizzi.services",
  ACCOUNT_ENDPOINT: isProduction ? "https://accounts.bizzi.vn" : "https://accounts-uat.bizzi.vn",
  HASURA_ENDPOINT: isProduction
    ? "https://graphql.bizzi.services/v1/graphql"
    : "https://graphql-uat.bizzi.services/v1/graphql",
  HASURA_ENDPOINT_WS: isProduction
    ? "wss://graphql.bizzi.services/v1/graphql"
    : "wss://graphql-uat.bizzi.services/v1/graphql",
  DEBUGGER: ["loc.truong@bizzi.vn", "loctruongh1@gmail.com", "loctruongh@gmail.com", "dung.phung@bizzi.vn"],
  GOOGLE_PLACES_KEY: "d2iF5dNcnQbj1sTUQnQ2B42XnPbFcCJXgBPGigFY",
  EXPENSE_EMAIL_SUFFIX: isProduction ? "hoadon.me" : "uat.hoadon.me",
  EXPENSE_CATEGORY_ICON: <IEXPENSE_CATEGORY_ICON>{
    hospitality: "bed-empty",
    travel: "airplane-takeoff",
    hostess: "food",
    empty: "alert-outline",
    others: "dots-horizontal-circle",
  },
  ACCOUNT_CONSTANT: {
    TOKEN_KEY_NAME: isProduction ? "token" : "token-uat",
    REFRESH_TOKEN_KEY_NAME: isProduction ? "refreshToken" : "refreshToken-uat",
    CREATED_EVENT: "Created",
    ROUTE_EVENT: "Route",
  },
  EXPENSE_APP_ENDPOINT: isProduction ? "https://expense.bizzi.app" : "https://expense-uat.bizzi.app",
  EXPENSE_SERVICE_ENDPOINT: isProduction ? "https://expense.bizzi.services" : "https://expense-uat.bizzi.services",
  AMPLITUDE_API_KEY: isProduction ? "bb7eee24eae47d0d3adb063fec5e979" : "9de49cdc49946b15bb73050105d868bf",
  APP_PACKAGE_NAME: isProduction ? "vn.bizzi.expense" : "vn.bizzi.expense.uat",
  ALLOCATED_FILE_TEMPLATE: isProduction
    ? "https://spend.bizzi.vn/file-template/Mau-phan-bo-chi-phi.xlsx"
    : "https://spend-uat.bizzi.vn/file-template/Mau-phan-bo-chi-phi.xlsx",
  EXPENSE_MINI_SERVICE_ENDPOINT: isProduction
    ? "https://p1x1sv7ot9.execute-api.ap-southeast-1.amazonaws.com/prod/api/v1"
    : "https://b9j1xcv3n9.execute-api.ap-southeast-1.amazonaws.com/uat/api/v1",
};
