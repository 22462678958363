import React, { FC, ReactNode, memo, useRef } from "react";

import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { TextInput as PaperTextInput } from "react-native-paper";

import { VNDIcon } from "assets/images/svg/icons";
import { useSetRelativePosition } from "components";
import AppText from "components/AppText";
import { CONSTANTS } from "constants/constants";
import { KeyboardTypeOptions } from "react-native/Libraries/Components/TextInput/TextInput";
import { Colors, FontTypes, Fonts } from "theme";
import { convertCurrencyStringToRawValue, formatMoney } from "utils";

interface ForeignCurrencyInputProps {
  style?: StyleProp<ViewStyle>;
  label?: string;
  name?: string;
  control: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  errorStyle?: "inline" | "summary";
  placeholder?: string;
  right?: ReactNode;
  autoFocus?: boolean;
  disabled?: boolean;
  bottomSheetMode?: boolean;
  setPosition?: (offsetY: number) => void;
  keyboardType?: KeyboardTypeOptions;
  containerRef?: any;
  onChange?: (value: number) => void;
  defaultValue?: string | number;
}

const formatWithThousandSeparator = (text: string): string => {
  // Remove all invalid characters except digits and ","
  let numericValue = text.replace(/[^0-9,]/g, "");

  // Replace the first "," encountered with a ".", assuming it's a decimal separator
  if (numericValue.includes(",")) {
    const [integerPart, decimalPart] = numericValue.split(",");
    numericValue = `${integerPart}.${decimalPart || ""}`;
  }

  // Split the integer and fractional parts
  const parts = numericValue.split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Format the integer part with "."

  // Combine the formatted integer part with the fractional part (if any) using ","
  return parts.length > 1 ? `${integerPart},${parts[1]}` : integerPart;
};

const ForeignCurrencyInput: FC<ForeignCurrencyInputProps> = ({
  style,
  label,
  name,
  control,
  rules,
  right,
  placeholder,
  error,
  disabled = false,
  errorStyle = "inline",
  setPosition,
  keyboardType = "decimal-pad",
  containerRef,
  onChange,
  defaultValue,
}) => {
  const textRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef: textRef, setPosition });

  const handleChange = (setValue) => (text) => {
    const displayValue = formatWithThousandSeparator(text);

    setValue(displayValue);
    onChange?.(convertCurrencyStringToRawValue(displayValue));
    /*-- handle input negative number on some Android devices (samsung,...) --*/
    // if (Platform.OS === "android" && (text === "." || text === "-")) {
    //   setValue(text);
    //   return;
    // }
  };
  return (
    <View ref={textRef} style={style}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          if (disabled) {
            return (
              <View style={styles.disabledContainer}>
                <AppText style={[Fonts.Caption, styles.label]}>{label}</AppText>
                <AppText style={[Fonts.BodyLarge, styles.flex]} color={Colors.grayscale80}>
                  {formatMoney(value)}
                </AppText>
                <VNDIcon />
              </View>
            );
          }
          return (
            <PaperTextInput
              disabled={disabled}
              dense
              onBlur={() => {
                return null;
              }}
              value={value}
              keyboardType={keyboardType}
              mode="outlined"
              onChangeText={handleChange(onChange)}
              label={label}
              placeholder={placeholder}
              style={styles.textInput}
              outlineColor={Colors.grayscale10}
              activeOutlineColor={Colors.primary50}
              //onChangeText={handleChange(onChange)}
              theme={{
                roundness: 8,
                colors: {
                  error: Colors.alert50,
                  background: Colors.while,
                  text: CONSTANTS.COMMON.TEXT_INPUT_COLOR,
                  placeholder: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
                },
                fonts: {
                  regular: {
                    fontFamily: FontTypes.medium,
                  },
                },
              }}
              error={Boolean(error?.message)}
              right={
                right ? (
                  <PaperTextInput.Icon
                    style={styles.rightIcon}
                    theme={{
                      colors: {
                        text: Colors.while,
                      },
                    }}
                    name={() => right}
                  />
                ) : null
              }
            />
          );
        }}
      />
      {errorStyle === "inline" && Boolean(error?.message) && (
        <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
          {error?.message as string}
        </AppText>
      )}
    </View>
  );
};

export default memo(ForeignCurrencyInput);

const styles = StyleSheet.create({
  errorText: { marginLeft: 0, marginTop: 4 },
  rightIcon: {
    paddingTop: 8,
  },
  textInput: {
    height: 48,
  },
  disabledContainer: {
    paddingVertical: 12,
    paddingLeft: 16,
    paddingRight: 12,
    minHeight: 48,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Colors.grayscale0,
    alignItems: "center",
    marginTop: 6,
  },
  flex: { flex: 1 },
  label: {
    position: "absolute",
    top: -8,
    left: 16,
    color: Colors.grayscale60,
  },
});
