import { ApolloError } from "@apollo/client";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { BizziBotInfo, BizziBotWarning, BizziBotWarning2, ErrorIcon } from "assets/images/svg/icons";
import { AlertNotification, AlertNotificationHandle, BackHeader, ToastManager, useBackHandler } from "components";
import AttachmentType from "constants/AttachmentType";
import { COMPANIES } from "constants/Companies";
import { ExpenseStatus, InvoiceType } from "constants/ExpenseApproval";
import { EVENT } from "constants/Tracking";
import {
  ACCOUNT_TYPES,
  COMPANY_ATTACHMENT_OBJECT_TYPES,
  COMPANY_ATTACHMENT_TYPES,
  RESTRICTION_BUDGET_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { useMasterData } from "contexts/MasterDataContext";
import dayjs from "dayjs";
import { useExpInsertExpenseAttachments } from "hooks/expense/useExpInsertExpenseAttachments";
import { useExpInsertExpenseMutation } from "hooks/expense/useExpInsertExpenseMutation";
import { useExpInsertExpenseReportMutation } from "hooks/reports/useExpInsertExpenseReportMutation";
import { useExpSubmitExpenseReportMutation } from "hooks/reports/useExpSubmitExpenseReportMutation";
import useUploadFileSignedURL from "hooks/useUploadFileSignedURL";
import { useValidateExpenseAmountMutation } from "hooks/useValidateExpenseAmountMutation";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { goBack } from "navigation/RootNavigation";
import SCREEN_NAME from "navigation/ScreenName";
import { NewExpenseScreenNavigationProp, NewExpenseScreenRouteProp } from "navigation/type";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StatusBar, StyleSheet, View } from "react-native";
import { useInsertCompanyAttachments } from "screens/Expense/hooks/useInsertCompanyAttachments";
import { AlertBudgetTracking } from "screens/ExpenseRequest/types";
import { analyticService, withTrackingSession } from "services/AnalyticsService";
import { MobileInsertExpenseMutation, MobileValidateExpenseAmountMutation } from "types";
import { convertCurrencyStringToRawValue, saveRecentCompanyTeamToStorage } from "utils";
import { executeRouteFunction } from "utils/route";
import { CLIENT_ERROR_CODE, SERVER_ERROR_CODE } from "utils/serverCode";
import ExpenseForm from "../components/ExpenseForm/ExpenseForm";
import {
  ExpenseFormAction,
  ExpenseFormValues,
  LOCATION_TYPE,
  MobileInsertExpenseMutationVariables,
} from "../components/ExpenseForm/types";

type CreateExpenseScreenProps = {
  route: NewExpenseScreenRouteProp;
  navigation: NewExpenseScreenNavigationProp;
};

const CreateExpenseScreen: FC<CreateExpenseScreenProps> = ({ navigation, route }) => {
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [approverId, setApproverId] = useState<string | undefined>(undefined);
  const [isDirty, setIsDirty] = useState(false);
  const { setting } = useMasterData();
  const isRequiredPaymentInfo = setting?.requiredPaymentInfo;
  const isRequiredLocation = setting?.requiredLocation;

  const initParamsRef = useRef(route?.params ?? {});
  const {
    invoiceId,
    invoiceType,
    photos,
    attachments,
    amount,
    cardTransaction,
    companyTeam,
    isGoBackAfterCreated = false,
    requireExpenseIsReady = false,
    shouldShowWalkthrough = false,
  } = initParamsRef.current;
  const {
    company,
    user: { employee_id },
  } = useAuth();
  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );

  const { uploadImage } = useUploadFileSignedURL();
  const [insertExpense] = useExpInsertExpenseMutation();
  const [submitExpenseReport] = useExpSubmitExpenseReportMutation();
  const [insertExpenseReport] = useExpInsertExpenseReportMutation();
  const [insertExpenseAttachments] = useExpInsertExpenseAttachments();
  const [insertCompanyAttachments] = useInsertCompanyAttachments();

  //const { getInvoice, dataInvoice } = useInvoiceByPkQuery({ invoiceId });
  const [validateExpenseBudgetAmount] = useValidateExpenseAmountMutation();
  const { t } = useTranslation("app/screens/Expense/CreateExpenseScreen");

  const handleError = (error?: ApolloError, title?: string) => {
    let message = t("an_error_occurred_during_processing");
    if (error?.message === SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW) {
      message = t("description_error_send_report");
    } else if (error?.graphQLErrors?.[0]?.extensions?.code === SERVER_ERROR_CODE.BAD_USER_INPUT) {
      message = error?.graphQLErrors?.[0]?.message;
    }
    alertNotificationRef.current?.error({
      onCancel: () => goBack(),
      title: title || t("expense_create_failed"),
      description: message,
    });
  };

  const handleSubmitExpenseReport = async (expenseId?: string, description?: string) => {
    try {
      const { data: expenseReportData } = await insertExpenseReport({
        variables: {
          expenseReport: {
            expenseIds: [expenseId],
            title: description ?? "",
          },
        },
      });
      const expenseReportId = expenseReportData?.expInsertExpenseReport.expenseReportId;
      if (!expenseReportId) {
        throw new Error(CLIENT_ERROR_CODE.CREATE_EXPENSE_REPORT_FAIL);
      }
      await submitExpenseReport({
        variables: {
          expenseReportId,
          approverEmployeeId: approverId,
        },
      });
      alertNotificationRef.current?.close();
      analyticService.logEvent({ sessionId: SCREEN_NAME.NewExpenseScreen, name: EVENT.REPORT.SUBMITTED_SUCCESS });
      ToastManager.success(t("expense_submission_successfully"));
      goBack();
      executeRouteFunction(route, "onRefreshData");
    } catch (error) {
      analyticService.logEvent({
        sessionId: SCREEN_NAME.NewExpenseScreen,
        name: EVENT.REPORT.SUBMITTED_FAILED,
        properties: {
          message: t("expense_submission_failed"),
          error: JSON.stringify(error),
        },
      });
      handleError(error, t("expense_submission_failed"));
    }
  };

  const handleUpdateExpense = async (
    type: ExpenseFormAction,
    values: ExpenseFormValues,
    expense: MobileInsertExpenseMutation
  ) => {
    try {
      alertNotificationRef.current?.loading();
      const { invoice, invoiceFiles, fileAttachments, note, allocatedExpense } = values;
      const { allocatedDocuments, allocatedExpenses, isOn: isEnableAllocatedExpense } = allocatedExpense ?? {};

      let newAttachments = [];
      const { expenseId, expenseReport, status } = expense?.expInsertExpense || {};

      if (!isEmpty(fileAttachments)) {
        const data = await uploadImage({
          type: 2,
          objectId: expenseId,
          images: fileAttachments,
          companyId: company.company_id,
          subAttachmentType: AttachmentType.TYPE_REPORT,
        });
        newAttachments = data.filter(Boolean);
      }

      if (invoice?.invoiceType === InvoiceType.PAPER && !isEmpty(invoiceFiles)) {
        const data = await uploadImage({
          objectId: expenseId,
          companyId: company.company_id,
          images: invoiceFiles,
          type: 1,
          subAttachmentType: AttachmentType.TYPE_REPORT,
        });
        newAttachments = [...newAttachments, ...data.filter(Boolean)];
      }

      if (!isEmpty(newAttachments)) {
        const attachments = newAttachments.map((value) => {
          return { ...value, expense_id: expenseId };
        });
        await insertExpenseAttachments({
          variables: {
            attachments,
          },
        });
      }

      if (!isEmpty(allocatedDocuments) && isEnableAllocatedExpense) {
        const data = await uploadImage({
          objectId: expenseId,
          images: allocatedDocuments,
          companyId: company.company_id,
          subAttachmentType: AttachmentType.TYPE_REPORT,
        });
        const { file_name: fileName, file_url: fileUrl, file_type: filetype, file_size: fileSize } = data[0];
        await insertCompanyAttachments({
          variables: {
            input: {
              companyId: company.company_id,
              objectId: expenseId,
              objectType: COMPANY_ATTACHMENT_OBJECT_TYPES.EXPENSE,
              attachments: [
                {
                  attachmentType: COMPANY_ATTACHMENT_TYPES.ALLOCATED_DOCUMENT as any,
                  fileUrl,
                  thumbnailUrl: fileUrl,
                  fileName,
                  fileSize,
                  mimeType: filetype,
                  customFields: {
                    allocatedExpenses,
                  },
                },
              ],
            },
          },
        });
      }

      if (type === ExpenseFormAction.CREATE_OR_UPDATE_EXPENSE) {
        ToastManager.success(t("create_expense_successfully"));
        /*-- flow add request cash advance to expense --> this expense auto add to report cash advance already exist --*/
        if (expenseReport && status === ExpenseStatus.READY && !isTLNExpenseCompany) {
          setIsDirty(false);
          alertNotificationRef.current?.confirm({
            disableClose: true,
            icon: <BizziBotInfo />,
            title: t("auto_add_expense_to_report_title"),
            description: t("auto_add_expense_to_report_description"),
            cancelText: t("view_report"),
            confirmText: t("understood"),
            onCancel: () => {
              goBack();
              executeRouteFunction(route, "onRefreshData");
            },
            onConfirm: () => {
              navigation.replace(SCREEN_NAME.ReportDetailScreen, {
                expenseReportId: expenseReport?.expenseReportId,
                onRefreshData: () => executeRouteFunction(route, "onRefreshData"),
              });
            },
          });
          return;
        }
        /*-- end --*/
        alertNotificationRef.current?.close();
        if (isGoBackAfterCreated) {
          goBack();
          executeRouteFunction(route, "onRefreshData");
        } else {
          onNavigateToExpenseDetail(expenseId);
        }
        return;
      }

      handleSubmitExpenseReport(expenseId, note);
    } catch (error) {
      analyticService.logEvent({
        sessionId: SCREEN_NAME.NewExpenseScreen,
        name: EVENT.EXPENSE.CREATED_FAILED,
        properties: {
          message: t("expense_create_failed"),
        },
      });
      handleError(error, t("expense_create_failed"));
    }
  };

  const handleInsertExpense = async ({
    type,
    values,
    validateExpenseResult,
    message,
  }: {
    type: ExpenseFormAction;
    values: ExpenseFormValues;
    validateExpenseResult?: MobileValidateExpenseAmountMutation["expValidateExpenseAmount"];
    message?: string;
  }) => {
    try {
      alertNotificationRef?.current?.loading();
      const {
        invoice, // electron invoice
        expenseDate,
        companyTeam,
        request,
        note,
        category: expenseCategoryId,
        placeDetails,
        amount: totalAmount,
        cardTransaction,
      } = values;

      const { invoiceType, invoiceElectronic } = invoice;
      const totalAmountWithVat = Number(totalAmount);
      const expenseHaveCardTransaction = Boolean(cardTransaction?.cardTransactionId) && cardTransaction?.isOn;

      const variables: MobileInsertExpenseMutationVariables = {
        expense: {
          note,
          provider: invoiceElectronic?.sellerLegalName,
          address: invoiceElectronic?.sellerAddressLine,
          invoiceId: invoiceElectronic?.invoiceId,
          expenseDate,
          placeDetails: null,
          companyTeamId: companyTeam?.companyTeamId,
          expenseRequestId: request?.expenseRequestId,
          customFields: {},
          expenseCategoryId,
          totalAmountWithVat,
          invoiceType: invoiceType as InvoiceType,
          cardTransactionId: expenseHaveCardTransaction ? cardTransaction?.cardTransactionId : undefined,
          subAccount: values?.categorySubAccount ? values?.categorySubAccount : undefined,
          currency: values?.currency?.isOn ? values?.currency?.name : undefined,
          exchangeRate: values?.currency?.isOn ? values?.currency?.exchangeRate : undefined,
          foreignTotalAmountWithVat:
            values?.currency?.isOn && values?.foreignTotalAmountWithVat
              ? convertCurrencyStringToRawValue(values?.foreignTotalAmountWithVat)
              : undefined,
        },
      };

      if (isRequiredPaymentInfo) {
        variables.expense.placeDetails = placeDetails?.location_name
          ? {
              ...placeDetails,
              type: undefined, //remove field type
            }
          : undefined;
      } else {
        variables.expense.placeDetails =
          placeDetails?.type === LOCATION_TYPE.AVAILABLE
            ? {
                ...placeDetails,
                type: undefined, //remove field type
              }
            : undefined;
      }

      /*--- flow save payment info to expense --> in the case have card transaction --> not send payment info ---*/
      if (values?.paymentInfo?.type !== ACCOUNT_TYPES.NONE && !expenseHaveCardTransaction) {
        variables.expense.paymentInfo = {
          accountHolderName: values?.paymentInfo?.accountHolderName,
          accountNumber: values?.paymentInfo?.accountNumber,
          bankCode: values?.paymentInfo?.bank?.metadata?.bankCode,
          bankId: values?.paymentInfo?.bank?.metadata?.bankId,
          bankName: values?.paymentInfo?.bank?.metadata?.bankName,
          bankShortName: values?.paymentInfo?.bank?.metadata?.bankShortName,
          branchName: values?.paymentInfo?.branchName,
          description: values?.paymentInfo?.description,
          logoUrl: values?.paymentInfo?.bank?.metadata?.logoUrl,
          type: values?.paymentInfo?.type,
        };
      }

      if (isTLNExpenseCompany) {
        const { vatRate, taxCode, invoiceCode } = values;
        const month =
          dayjs(expenseDate).month() + 1 < 9 ? `0${dayjs(expenseDate).month() + 1}` : dayjs(expenseDate).month() + 1;
        const year = dayjs(expenseDate).year().toString().substring(2);
        variables.expense.expenseCategoryId = undefined;
        variables.expense.customFields = {
          vat_rate: vatRate,
          invoice_code: invoiceCode,
          vat_period: `VAT${month}${year}`,
          tax_code: taxCode,
        };
      }

      const { data } = await insertExpense({ variables });
      if (!data?.expInsertExpense) {
        throw new Error(CLIENT_ERROR_CODE.CREATE_EXPENSE_FAIL);
      }

      executeRouteFunction(route, "onRefreshData");
      await handleUpdateExpense(type, values, data);

      analyticService.logEvent({
        sessionId: SCREEN_NAME.NewExpenseScreen,
        name: EVENT.EXPENSE.CREATED_SUCCESS,
        properties: {
          object_id: data.expInsertExpense.expenseId,
          object_type: TRACKING_OBJECT_TYPE.EXPENSE,
          message,
          over_budgets:
            validateExpenseResult && !validateExpenseResult?.budget?.isValid
              ? [
                  {
                    expense_category_id: values?.category,
                    company_team_id: companyTeam?.companyTeamId,
                    over_amount: validateExpenseResult?.budget?.overBudgetAmount,
                    expense_date: values?.expenseDate,
                  },
                ]
              : [],
          over_requests:
            validateExpenseResult && !validateExpenseResult?.expenseRequest?.isValid
              ? [
                  {
                    expense_category_id: values?.category,
                    company_team_id: companyTeam?.companyTeamId,
                    over_amount: validateExpenseResult?.expenseRequest?.overRequestAmount,
                  },
                ]
              : [],
        },
      });
    } catch (error) {
      analyticService.logEvent({
        sessionId: SCREEN_NAME.NewExpenseScreen,
        name: EVENT.EXPENSE.CREATED_FAILED,
        properties: {
          message: t("expense_create_failed"),
        },
      });

      handleError(error, t("expense_create_failed"));
    }
  };

  const submitExpense = async (type: ExpenseFormAction, values: ExpenseFormValues) => {
    try {
      // in the case is TLN company: no need validate budget
      if (isTLNExpenseCompany) {
        await handleInsertExpense({ type, values });
        return;
      }
      /**
       * check budget validation before
       */
      alertNotificationRef.current?.loading();
      const companyId = company.company_id;
      const { companyTeam, expenseDate, category: expenseCategoryId, request, amount, invoice } = values;
      const spendingAmount = Number(amount);
      const { data } = await validateExpenseBudgetAmount({
        variables: {
          input: {
            companyId,
            expenseDate,
            companyTeamId: companyTeam?.companyTeamId,
            spendingAmount,
            spendingAmountWithVat: spendingAmount,
            spendingAmountWithoutVat: invoice?.invoiceElectronic?.totalAmountWithoutVat,
            expenseRequestId: request?.expenseRequestId,
            expenseCategoryId,
          },
        },
      });

      const validateExpenseAmountResult = data?.expValidateExpenseAmount;

      const validateBudget = validateExpenseAmountResult?.budget;
      const validateExpenseRequest = validateExpenseAmountResult?.expenseRequest;
      if (!validateExpenseAmountResult) {
        throw new Error(CLIENT_ERROR_CODE.CREATE_EXPENSE_REPORT_FAIL);
      }

      if (
        (validateBudget?.isValid || validateBudget?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT) &&
        (validateExpenseRequest?.isValid || validateExpenseRequest?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT)
      ) {
        await handleInsertExpense({ type, values });
        return;
      }

      if (
        (!validateExpenseRequest?.isValid &&
          validateExpenseRequest?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT) ||
        (!validateBudget?.isValid && validateBudget?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT)
      ) {
        const properties: AlertBudgetTracking = {
          message: t("restrict_budget_expense"),
          restriction_type: RESTRICTION_BUDGET_TYPE.RESTRICT,
          object_type: TRACKING_OBJECT_TYPE.EXPENSE,
          over_budgets: [],
          over_requests: [],
        };
        if (!validateBudget?.isValid) {
          properties.over_budgets = [
            {
              expense_category_id: expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: validateBudget?.overBudgetAmount,
              expense_date: expenseDate,
            },
          ];
        }
        if (validateExpenseRequest && !validateExpenseRequest.isValid) {
          properties.over_requests = [
            {
              expense_category_id: expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: validateExpenseRequest?.overRequestAmount,
            },
          ];
        }

        alertNotificationRef.current?.info({
          icon: <BizziBotWarning2 />,
          title: t("budget_amount_warning_title"),
          description: t("restrict_budget_expense"),
          confirmText: t("understood"),
          onConfirm: () => {
            alertNotificationRef?.current?.close();
          },
        });

        analyticService.logEvent({
          name: EVENT.SYSTEM_ALERT.RESTRICT_EXPENSE_BUDGET,
          properties,
        });
        return;
      }
      const messages: string[] = [];
      if (
        !validateExpenseAmountResult.budget.isValid &&
        validateExpenseAmountResult.budget.restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
      ) {
        messages.push(validateExpenseAmountResult.budget.message);
      }
      if (
        !validateExpenseAmountResult.expenseRequest.isValid &&
        validateExpenseAmountResult.expenseRequest.restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
      ) {
        messages.push(validateExpenseAmountResult.expenseRequest.message);
      }
      messages.push(t("budget_amount_warning_context"));
      const properties: AlertBudgetTracking = {
        message: uniq(messages).join(".\n"),
        restriction_type: RESTRICTION_BUDGET_TYPE.WARNING,
        object_type: TRACKING_OBJECT_TYPE.EXPENSE,
        over_budgets: [],
        over_requests: [],
      };
      if (!validateBudget?.isValid) {
        properties.over_budgets = [
          {
            expense_category_id: expenseCategoryId,
            company_team_id: companyTeam?.companyTeamId,
            over_amount: validateBudget?.overBudgetAmount,
            expense_date: expenseDate,
          },
        ];
      }
      if (!validateExpenseRequest?.isValid) {
        properties.over_requests = [
          {
            expense_category_id: expenseCategoryId,
            company_team_id: companyTeam?.companyTeamId,
            over_amount: validateExpenseRequest?.overRequestAmount,
          },
        ];
      }
      analyticService.logEvent({
        name: EVENT.SYSTEM_ALERT.WARNING_EXPENSE_BUDGET,
        properties,
      });
      alertNotificationRef.current?.confirm({
        icon: <BizziBotWarning2 />,
        title: t("budget_amount_warning_title"),
        description: uniq(messages).join(".\n"),
        cancelText: t("back"),
        confirmText: t("confirm"),
        onCancel: () => {
          alertNotificationRef.current?.close();
        },
        onConfirm: () => {
          handleInsertExpense({
            type,
            values,
            validateExpenseResult: validateExpenseAmountResult,
            message: uniq(messages).join(".\n"),
          });
        },
      });
    } catch (error) {
      analyticService.logEvent({ sessionId: SCREEN_NAME.NewExpenseScreen, name: EVENT.EXPENSE.CREATED_FAILED });
    }
  };

  const handleSubmit = async (type: ExpenseFormAction, values: ExpenseFormValues) => {
    try {
      if (requireExpenseIsReady) {
        const { invoice } = values;
        const existElectronicInvoice =
          invoice?.invoiceType === InvoiceType.ELECTRONIC && invoice?.invoiceElectronic?.invoiceId;
        const existPaperInvoice = invoice?.invoiceType === InvoiceType.PAPER && !isEmpty(values?.invoiceFiles);
        if (
          (invoice?.invoiceType !== InvoiceType.NO_INVOICE && !existElectronicInvoice && !existPaperInvoice) ||
          (isRequiredLocation && !values?.placeDetails?.location_name)
        ) {
          alertNotificationRef.current?.info({
            icon: <ErrorIcon />,
            title: t("expense_create_failed"),
            description: t("require_expense_is_ready_error"),
            confirmText: t("understood"),
            onConfirm: () => alertNotificationRef?.current?.close(),
          });
          return;
        }
      }
      setIsSubmitting(true);
      await submitExpense(type, values);
    } finally {
      setIsSubmitting(false);
      // flow save recent company team to storage
      if (values?.companyTeam) {
        await saveRecentCompanyTeamToStorage({
          companyId: company?.company_id,
          employeeId: employee_id,
          companyTeam: values?.companyTeam,
        });
      }
    }
  };

  const onNavigateToExpenseDetail = (expenseId) => {
    navigation.replace(SCREEN_NAME.ExpenseDetailScreen, { expenseId });
    executeRouteFunction(route, "onRefreshData");
  };

  const showAlert = () => {
    alertNotificationRef.current?.warning({
      icon: <BizziBotWarning />,
      title: t("cancel_title"),
      description: t("cancel_confirm"),
      cancelText: t("close"),
      confirmText: t("cancel"),
      onConfirm: () => {
        alertNotificationRef.current?.close();
        goBack();
        executeRouteFunction(route, "onRefreshData");
      },
    });
  };

  const handleGoBack = useCallback(() => {
    if (!isDirty) {
      goBack();
      return;
    }
    showAlert();
  }, [isDirty, navigation, showAlert]);
  useBackHandler(() => {
    if (isDirty) {
      showAlert();
      return true;
    }
    return false;
  });

  return (
    <BottomSheetModalProvider>
      <StatusBar barStyle="dark-content" />
      <BackHeader headerTitle={t("create_expense")} onPress={handleGoBack} />
      <View style={styles.container}>
        <ExpenseForm
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          saveDraftText={t("create_expense")}
          allowSendReport={isTLNExpenseCompany}
          defaultValue={{ amount, invoiceId, invoiceType, photos, attachments, cardTransaction, companyTeam }}
          autoFocusAmountInput
          approverId={approverId}
          setApproverId={setApproverId}
          setIsDirty={setIsDirty}
          shouldShowWalkthrough={shouldShowWalkthrough}
        />
        <AlertNotification goBackIsDismiss ref={alertNotificationRef} />
      </View>
    </BottomSheetModalProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default withTrackingSession(CreateExpenseScreen, SCREEN_NAME.NewExpenseScreen);
